import React from 'react'
import './Footer.css';

function Footer(){
    return(
        <>
 
<div class="footer-contact-bar">
<div className='footer-logo'>
        <img src='https://img.freepik.com/premium-vector/creative-elegant-minimalistic-logo-design-vector-any-brand-business-company_1287271-9313.jpg?semt=ais_hybrid&w=740'></img>
    </div>

  <div class="footer-contact-inner">

    <div class="footer-contact-item">
      <i class="fas fa-phone-alt contact-icon"></i>
      <div>
        <p>Call Us:</p>
        <a href="tel:+918830480949">+91 8830 480 949</a>
      </div>
    </div>
    <div class="footer-contact-item">
      <i class="fas fa-envelope contact-icon"></i>
      <div>
        <p>Send email</p>
        <a href="mailto:info@mindmatrixtech.com">info@mindmatrixtech.com</a>
      </div>
    </div>
  </div>
</div>

<footer class="main-footer">

  <div class="footer-content">
    <div class="footer-section address">
      <h4>Address</h4>
      <p>Office no 301, 11 Mayur, Karve Road,<br></br>Pune, India 411038</p>
      <div class="social-icons">
        <i class="fab fa-linkedin-in"></i>
        <i class="fab fa-facebook-f"></i>
        <i class="fab fa-youtube"></i>
      </div>
    </div>
    <div class="footer-section links">
      <h4>Links</h4>
      <ul>
        <li><a href="#">About</a></li>
        <li><a href="#">Services</a></li>
        <li><a href="#">Contact</a></li>
      </ul>
    </div>
    <div class="footer-section newsletter">
      <h4>Newsletter</h4>
      <p>Subscribe our newsletter to get<br></br>our latest update & news</p>
      <div class="newsletter-input">
        <input type="email" placeholder="Email address" />
        <button><i class="fas fa-paper-plane"></i></button>
      </div>
    </div>
  </div>
  
  <div class="footer-bottom">
    <p>© Copyright 2023 Courses Technologies</p>
  </div>

</footer>


        </>
    )
}
export default Footer;