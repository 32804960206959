import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa'; // using react-icons for arrow

import './Home.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faPhone  } from '@fortawesome/free-solid-svg-icons';

function Home(){
    const [activeIndex, setActiveAcordion] = useState(0);
    const contentRefs = useRef([]);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        // beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
      };
      const images = [
        "https://img.freepik.com/premium-vector/abstract-blue-modern-banner-background-vector-abstract-graphic-design-banner-pattern-background-template_181182-18212.jpg",
        "https://t4.ftcdn.net/jpg/05/54/46/89/360_F_554468927_iwU3VYIjsaeopAb0WPYxVf21TloEhTEj.jpg"
      ];

      const content = [
        {
          title: "Welcome to Our Website",
          subtitle: "Your trusted partner for digital success Your trusted partner for digital success Your trusted partner for digital success"
        },
        {
          title: "Explore Our Services",
          subtitle: "Your trusted partner for digital success Your trusted partner for digital success Your trusted partner for digital success"
        },
        {
          title: "Join Our Community",
          subtitle: "Your trusted partner for digital success Your trusted partner for digital success Your trusted partner for digital success"
        },
        {
          title: "Innovate With Us",
          subtitle: "Your trusted partner for digital success Your trusted partner for digital success Your trusted partner for digital success"
        },
        {
          title: "Delivering Excellence",
          subtitle: "Your trusted partner for digital success Your trusted partner for digital success Your trusted partner for digital success"
        }
      ];
    
      const toggleAccordion = (index) => {
        setActiveAcordion(prevIndex => (prevIndex === index ? null : index));
      };
      const items = [
        {
          title: 'Expertise',
          content:
            'Our team of experienced designers and developers have a deep understanding of the latest design trends and technologies, and are committed to delivering high-quality work that exceeds your expectations.',
        },
        {
          title: 'Customized Solutions',
          content: 'We provide solutions tailored to your specific needs and goals.',
        },
        {
          title: 'Dedicated Support',
          content: 'Our support team is always ready to assist you promptly.',
        },
        {
          title: 'Collaborative Approach',
          content: 'We work closely with you to bring your ideas to life.',
        },
        {
          title: 'Customer Satisfaction',
          content: 'We prioritize your satisfaction above everything else.',
        },
      ];
    
      useEffect(() => {
        contentRefs.current.forEach((ref, index) => {
          if (ref) {
            if (activeIndex === index || index === 0) {
              ref.style.maxHeight = ref.scrollHeight + 'px';
            } else {
              ref.style.maxHeight = '0px';
            }
          }
        });
      }, [activeIndex]);

    return(
        <>

                

            <section className="sliderSection">
                <div className="bannerImage">
                    <Slider {...settings}>
                    {images.map((img, index) => (
                        <div key={index}>
                        <img src={img} alt={`slide-${index}`} />
                        </div>
                    ))}
                    </Slider>

                    {/* Animated overlay image */}
                    <div className="overlayRightImage">
                    <img
                        src="https://mindmatrixtech.com/assets/images/shapes/main-slider-two-shape-1.png"
                        alt="Overlay 1"
                        className="staticOverlay"
                    />
                    <img
                        src="https://mindmatrixtech.com/assets/images/shapes/main-slider-two-shape-2.png"
                        alt="Overlay 2"
                        className="animatedOverlay"
                    />

                    {/* Content synced with slider */}
                    <div className="overlayContent">
                        <h2 className="slideText">{content[activeIndex].title}</h2>
                        <p style={{width:'350px',marginLeft:'160px'}}>{content[activeIndex].subtitle}</p>
                    </div>
                    </div>
                </div>
                </section>


            <section className='aboutSection'>
                <div className='aboutUs col-6 col-md-6'>
                    <img
                    className="mainImage"
                    src="https://st2.depositphotos.com/3591429/6006/i/450/depositphotos_60063963-stock-photo-people-discussing-about-us.jpg"
                    alt="About Us"
                    />
                    <img
                    className="topRightCircle"
                    src="https://randomuser.me/api/portraits/women/44.jpg"
                    alt="Top Right"
                    />
                </div>
                <div className="courseContent col-6 col-md-6">
                    <p>About Us</p>
                    <h2>Full Stack Web Development</h2>
                    <p>
                    Master both front-end and back-end web development with our comprehensive Full Stack course.
                    Learn HTML, CSS, JavaScript, React, Node.js, Express, and MongoDB. Build real-world projects,
                    understand deployment, and become job-ready with practical experience and expert mentorship.
                    Master both front-end and back-end web development with our comprehensive Full Stack course.
                    Learn HTML, CSS, JavaScript, React, Node.js, Express, and MongoDB. Build real-world projects,
                    understand deployment, and become job-ready with practical experience and expert mentorship.
                    </p>
                    <button className="discoverBtn">
                        DISCOVER MORE
                        <FontAwesomeIcon icon={faArrowRight} className="arrowIcon" />
                        </button>
                </div>
                </section>

                <section>
                    <div className='whatWeDo'>
                        <p>What we love to do</p>
                        <h2>Crafting Creative Solutions</h2>
                    </div>
                    <div className='whatWeDocardsSection'>
                        <div className='whatWeDocards shadow-sm'>
                            <div class="image-container">
                                <img src="https://img.freepik.com/free-photo/college-students-different-ethnicities-cramming_23-2149891341.jpg?semt=ais_hybrid&w=740" alt="Students"></img>
                            </div>
                                <h3>Digital Design</h3>
                                <p>Transform your online presence with our comprehensive design solutions. From branding to print, digital, and beyond, we bring your vision to life. Let's connect with your audience in meaningful and memorable ways
                                Transform your online presence with our comprehensive design solutions. From branding to print, digital, and beyond, we bring your vision to life. Let's connect with your audience in meaningful and memorable ways.</p>
                                <button>Read More</button>
                        </div>
                        <div className='whatWeDocards shadow-sm'>
                            <div class="image-container">
                                <img src="https://img.freepik.com/free-photo/college-students-different-ethnicities-cramming_23-2149891341.jpg?semt=ais_hybrid&w=740" alt="Students"></img>
                            </div>
                                <h3>Digital Design</h3>
                                <p>Transform your online presence with our comprehensive design solutions. From branding to print, digital, and beyond, we bring your vision to life. Let's connect with your audience in meaningful and memorable ways
                                Transform your online presence with our comprehensive design solutions. From branding to print, digital, and beyond, we bring your vision to life. Let's connect with your audience in meaningful and memorable ways.</p>
                                <button>Read More</button>
                        </div>
                        <div className='whatWeDocards shadow-sm'>
                            <div class="image-container">
                                <img src="https://img.freepik.com/free-photo/college-students-different-ethnicities-cramming_23-2149891341.jpg?semt=ais_hybrid&w=740" alt="Students"></img>
                            </div>
                                <h3>Digital Design</h3>
                                <p>Transform your online presence with our comprehensive design solutions. From branding to print, digital, and beyond, we bring your vision to life. Let's connect with your audience in meaningful and memorable ways
                                Transform your online presence with our comprehensive design solutions. From branding to print, digital, and beyond, we bring your vision to life. Let's connect with your audience in meaningful and memorable ways.</p>
                                <button>Read More</button>
                        </div>
                    </div>
                </section>

                <section className='logoSection shadow-sm'>
                    <Slider
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={4}
                        slidesToScroll={1}
                        autoplay={true}
                        autoplaySpeed={2000}
                        responsive={[
                        {
                            breakpoint: 1024,
                            settings: {
                            slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                            slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: 480,
                            settings: {
                            slidesToShow: 1,
                            },
                        },
                        ]}
                    >
                        {[...Array(6)].map((_, i) => (
                        <div className='logosImage' key={i}>
                            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlJuN4gPUiAUmxY4yifBn-1vlyi4YrI75S1g&s' alt={`Logo ${i}`} />
                        </div>
                        ))}
                    </Slider>
                </section>


                <section class="whyChooseUs">
                    <div className='backgrounImage'>
                    <div class="whyLeft">
                        <p class="subheading">Why choose us</p>
                        <h2>Why Our partners appreciate collaborating with us?</h2>

                        <div className="accordion">
                            {items.map((item, index) => (
                                <div
                                className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                                key={index}
                                >
                                <button
                                    className="accordion-title"
                                    onClick={() => toggleAccordion(index)}
                                >
                                    <span className="title-text">{item.title}</span>
                                    <FaChevronDown
                                    className={`icon ${activeIndex === index ? 'rotate' : ''}`}
                                    />
                                </button>
                                <div
                                    ref={(el) => (contentRefs.current[index] = el)}
                                    className="accordion-content"
                                >
                                    <p>{item.content}</p>
                                </div>
                                </div>
                            ))}
                            </div>
                    </div>

                    </div>
                   

                    <div class="whyRight"></div>
                </section>



                <section className="testimonial-section">
                <p className="testimonial-subtitle">Testimonials</p>
                    <h2 className="testimonial-title">What our clients say about us</h2>
                    
                    <div className="testimonial-cards">
               
                        <div className="testimonial-card">
               
                        <p className="testimonial-text">
                            MindMatrix Team's performance is always beyond the duty, always happy with design, services and support.
                        </p>
                        <div className="testimonial-info">
                            <div className="testimonial-img-wrapper">
                                <img src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359554_1280.png" alt="Harry" className="testimonial-img" />
                            </div>
                            <div>
                            <h4 className="testimonial-name">Harry Broadman</h4>
                            <p className="testimonial-role">CSO & CO-FOUNDER, AdvisoryKonnect</p>
                            </div>
                        </div>
                        <span className="testimonial-quote">❝❞</span>
                        </div>

                        <div className="testimonial-card">
                        <p className="testimonial-text">
                            We have partnered with many small and big projects, and MindMatrix team has been great help in implementing it.
                        </p>
                        <div className="testimonial-info">
                            <img src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359554_1280.png" alt="Shaliya" className="testimonial-img" />
                            <div>
                            <h4 className="testimonial-name">Shaliya Khan</h4>
                            <p className="testimonial-role">Partner, White Ideas Media</p>
                            </div>
                        </div>
                        <span className="testimonial-quote">❝❞</span>
                        </div>
                    </div>
                    </section>

                    <section className='GetTouchWithUs'>
                        <div className='call-one-shape-1'>
                             <h3>Get in touch with us</h3>
                        </div>
                        <div className='call-one-shape-2'>
                        <button className="call-button">
                            <span className="callIcon">
                                <FontAwesomeIcon icon={faPhone} />
                            </span>
                            +91 1234567890
                        </button>
                        </div>
                    </section>

                    


        

        </>
    )
}
export default Home;