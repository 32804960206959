import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
<>

    <div className="script">
    <div className='scriptContent'>
      <p><i className="fas fa-map-marker-alt"></i>  Office no 301, 11 Mayur, Karve Road, Pune, India 411038</p>
      <p><i className="fas fa-envelope"></i> info@mindmatrixtech.com</p>
      <p><i className="fas fa-clock"></i> Mon to Sat 09:00 To 21:00, IST</p>
      <p className='socialIcon'>
        <i className="fab fa-linkedin-in"></i>
        <i className="fab fa-facebook-f"></i>
        <i className="fab fa-youtube"></i>
      </p>

    </div>
    </div>
    <header>
      <nav className="navbar">
        <div className="logo">Courses</div>
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
          <li><Link to="/services" onClick={toggleMenu}>Services</Link></li>
          <li><Link to="/gallery" onClick={toggleMenu}>Gallery</Link></li>
          <li><Link to="/blogs" onClick={toggleMenu}>Blogs</Link></li>
          <li><Link to="/career" onClick={toggleMenu}>Career</Link></li>
          <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
          <button className="btn btn-primary">Login</button>
        </ul>
      </nav>
    </header>
    </>
  );
}

export default Header;
